"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.collectProperties = exports.mapLeaves = exports.filterLeaves = exports.slow = exports.one = exports.asyncFilter = exports.isNotNullish = exports.retainProperties = exports.assertDefined = exports.unreachable = void 0;
/**
 * Useful for checking exhaustiveness of `switch` statements at compile time.
 * @throws always
 */
function unreachable(value) {
    throw new Error(`Unreachable reached: ${value}`);
}
exports.unreachable = unreachable;
/**
 * @throws if the given value is `undefined`
 * @returns the given value, with `undefined` removed from its type
 */
function assertDefined(value, msg) {
    if (value === undefined) {
        throw new Error(msg !== null && msg !== void 0 ? msg : 'Assertion error');
    }
    return value;
}
exports.assertDefined = assertDefined;
/**
 * Useful in combination with `keys` from `ts-transformer-keys`.
 * @returns A shallow copy of the object, only retaining properties named in (or matched by a `RegExp` in) `props`.
 */
function retainProperties(obj, props) {
    const keys = new Set();
    for (const prop of props) {
        if (prop instanceof RegExp) {
            for (const key of Object.keys(obj)) {
                if (prop.test(key.toString())) {
                    keys.add(key);
                }
            }
        }
        else {
            keys.add(prop);
        }
    }
    const output = Object.create(Object.getPrototypeOf(obj));
    for (const key of keys) {
        const value = Object.getOwnPropertyDescriptor(obj, key);
        if (value !== undefined) {
            Object.defineProperty(output, key, value);
        }
    }
    return output;
}
exports.retainProperties = retainProperties;
/**
 * @returns `false` if `value` is `null` or `undefined`, otherwise `true`
 * Needed for proper type inference in some cases, such as RxJS `filter`.
 */
function isNotNullish(value) {
    return value !== null && value !== undefined;
}
exports.isNotNullish = isNotNullish;
/**
 * `Array.prototype.filter` except the predicate can be async.
 * @link https://advancedweb.hu/how-to-use-async-functions-with-array-filter-in-javascript/
 */
function asyncFilter(array, predicate, thisArg) {
    return __awaiter(this, void 0, void 0, function* () {
        const results = yield Promise.all(array.map(predicate, thisArg));
        return array.filter((value, index) => results[index]);
    });
}
exports.asyncFilter = asyncFilter;
/**
 * @param value - An array of length 1, or a non-array value
 * @returns The first element of the array, or if given a non-array the value itself
 * @throws If given an array with length > 1
 */
function one(value) {
    if (Array.isArray(value)) {
        if (value.length > 1) {
            throw new Error(`one() given an array of length ${value.length}`);
        }
        return value[0];
    }
    return value;
}
exports.one = one;
/**
 * Resolves with its input value, after a delay (in ms).
 * Useful for testing loading behaviors involving async operations.
 */
function slow(value, delay = 1000) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(value);
        }, delay);
    });
}
exports.slow = slow;
/**
 * Clones the object, leaving only properties for which `cond` returns true.
 * Recurses into objects, only ever passing leaves to `cond`. Doesn't recurse into arrays.
 */
function filterLeaves(obj, cond) {
    const out = {};
    for (const [key, value] of Object.entries(obj)) {
        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
            out[key] = filterLeaves(value, cond);
        }
        else if (typeof key !== 'string' || cond(value, key)) {
            out[key] = value;
        }
    }
    return out;
}
exports.filterLeaves = filterLeaves;
/**
 * Maps the values of all leaves of the given object using the given function.
 * Recurses into objects, only ever passing leaves to `fn`. Doesn't recurse into arrays.
 */
function mapLeaves(obj, fn) {
    const out = obj;
    for (const [key, value] of Object.entries(obj)) {
        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
            out[key] = mapLeaves(value, fn);
        }
        else {
            out[key] = fn(value, key);
        }
    }
    return out;
}
exports.mapLeaves = mapLeaves;
/**
 * Converts a subset of object properties into a nested object. Fields that are not found in the source are set to `undefined`.
 *
 * @argument container The object to mutate
 * @argument outputProperty Property to put the nested object in
 * @argument fields A mapping of source properties to output properties (see example)
 *
 * @example
 * let foo = { first: 1, second: 2, third: 3 };
 * collectProperties(foo, 'things', { first: 'eka', second: 'toka' });
 * // foo is now { things: { eka: 1, toka: 2 }, third: 3 }
 */
function collectProperties(container, outputProperty, fields) {
    const obj = {};
    for (const [from, to] of Object.entries(fields)) {
        obj[to] = container[from];
        delete container[from];
    }
    container[outputProperty] = obj;
    return container;
}
exports.collectProperties = collectProperties;
