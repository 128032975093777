"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_bootstrap_1 = require("react-bootstrap");
const react_i18next_1 = require("react-i18next");
/**
 * An animated loading spinner.
 */
exports.default = (0, react_1.memo)(Spinner);
function Spinner(props) {
    // can't Suspense in Spinner, because it's used as a Suspense indicator
    const { t, ready } = (0, react_i18next_1.useTranslation)('webapp-defaults', { useSuspense: false });
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(react_bootstrap_1.Spinner, Object.assign({}, Object.assign(Object.assign({ role: 'status', animation: 'border', className: 'm-3' }, props), { children: undefined }))), (0, jsx_runtime_1.jsx)("span", { className: "sr-only", children: ready ? t('spinner.text') : 'Loading...' })] }));
}
