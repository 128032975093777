import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ErrorAlert, Spinner, useAuthorization, useTranslation } from 'platform-webapp-frontend';
import { useAppMetadata } from '../apis/apps';
import { resolveLocalizedString, resolvePlaceholders } from '../util/localized-string';
import _ from 'lodash';
import { Button, Card } from 'react-bootstrap';
import { useMemo } from 'react';
function App({ app }) {
    const { t, i18n } = useTranslation('landing');
    const authorization = useAuthorization();
    const title = resolveLocalizedString(app.title, i18n.languages);
    const description = resolveLocalizedString(app.description, i18n.languages);
    let url = resolveLocalizedString(app.url, i18n.languages);
    url = resolvePlaceholders(url, authorization.user);
    return (_jsx(Card, Object.assign({ className: "h-100" }, { children: _jsxs(Card.Body, { children: [_jsx(Card.Title, { children: title }), _jsx(Card.Text, { children: description !== null && description !== void 0 ? description : '' }), _jsx(Button, Object.assign({ variant: "primary", href: url }, { children: t('open-app') }))] }) })));
}
function AppList({ className, title, apps }) {
    const { i18n } = useTranslation('landing');
    const sortedApps = useMemo(() => {
        const sorted = apps.slice();
        sorted.sort((a, b) => {
            var _a, _b;
            const aTitle = (_a = resolveLocalizedString(a.title, i18n.languages)) !== null && _a !== void 0 ? _a : '';
            const bTitle = (_b = resolveLocalizedString(b.title, i18n.languages)) !== null && _b !== void 0 ? _b : '';
            return aTitle.localeCompare(bTitle);
        });
        return sorted;
    }, [i18n, apps]);
    return (_jsxs(_Fragment, { children: [_jsx("h3", Object.assign({ className: className }, { children: title })), _jsx("div", Object.assign({ className: "row row-cols-1 row-cols-md-3 row-cols-xl-4 g-4" }, { children: sortedApps.map((app) => (_jsx("div", Object.assign({ className: "col" }, { children: _jsx(App, { app: app }) })))) }))] }));
}
export default function Apps() {
    const { t } = useTranslation('landing');
    const { data, error, loading } = useAppMetadata();
    if (loading) {
        return _jsx(Spinner, {});
    }
    if (error) {
        return _jsx(ErrorAlert, { message: t('error-loading'), redirectPath: "/" });
    }
    const { false: apps, true: sysadminApps } = _.groupBy(data !== null && data !== void 0 ? data : [], (app) => app.sysadmin);
    return (_jsxs(_Fragment, { children: [_jsx(AppList, { title: t('apps'), apps: apps }), sysadminApps && _jsx(AppList, { className: "mt-4", title: t('sysadmin-apps'), apps: sysadminApps })] }));
}
