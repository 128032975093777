var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _TestUser_data;
import { roles } from '../types';
class TestUser {
    constructor() {
        _TestUser_data.set(this, void 0);
        const storedData = localStorage.getItem('test-user');
        __classPrivateFieldSet(this, _TestUser_data, storedData
            ? JSON.parse(storedData)
            : {
                name: 'Test User',
                email: 'test-user@example.com',
                roles: roles,
                tenant: 'mipro',
                sub: 'test-user',
            }, "f");
    }
    set(testUser) {
        __classPrivateFieldSet(this, _TestUser_data, Object.assign(Object.assign({}, __classPrivateFieldGet(this, _TestUser_data, "f")), testUser), "f");
        localStorage.setItem('test-user', JSON.stringify(__classPrivateFieldGet(this, _TestUser_data, "f")));
    }
    get() {
        return JSON.stringify({
            name: __classPrivateFieldGet(this, _TestUser_data, "f").name,
            email: __classPrivateFieldGet(this, _TestUser_data, "f").email,
            tenant: __classPrivateFieldGet(this, _TestUser_data, "f").tenant,
            sub: __classPrivateFieldGet(this, _TestUser_data, "f").sub,
            roles: {
                '': __classPrivateFieldGet(this, _TestUser_data, "f").roles,
                'mipro-landing': __classPrivateFieldGet(this, _TestUser_data, "f").roles,
            },
        });
    }
}
_TestUser_data = new WeakMap();
const testUser = new TestUser();
export default testUser;
