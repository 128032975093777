"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TypesenseContext = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const platform_webapp_util_1 = require("platform-webapp-util");
const react_1 = require("react");
const authorization_1 = require("../../authorization");
const api_1 = require("../../api");
exports.TypesenseContext = (0, react_1.createContext)({
    getApiKey(key) {
        console.error('placeholder getApiKey');
        return Promise.resolve(undefined);
    },
    invalidateApiKeys: () => {
        console.error('placeholder invalidateApiKeys');
    },
    get typesenseURL() {
        console.error('placeholder typesenseURL read');
        return '';
    },
});
const useCreateApiKey = (searchServiceURL) => (0, api_1.useApi)([], {}, {
    apiURL: searchServiceURL,
    includeToken: true,
});
/**
 * Provides a Typesense API key and search results to the children components.
 */
const TypesenseContextProvider = ({ children, searchServiceURL, typesenseURL }) => {
    var _a;
    const authorization = (0, authorization_1.useAuthorization)();
    const tenant = (0, platform_webapp_util_1.assertDefined)((_a = authorization.user) === null || _a === void 0 ? void 0 : _a.tenant);
    const apiKeyLocalStorageName = `${tenant}-typesense-api-key`;
    const [isError, setIsError] = (0, react_1.useState)(false);
    const { post: createApiKey } = useCreateApiKey(searchServiceURL);
    const invalidateApiKeys = () => {
        const removeKeys = new Set();
        for (let i = 0; i < localStorage.length; ++i) {
            const localStorageKey = localStorage.key(i);
            if (localStorageKey === null || localStorageKey === void 0 ? void 0 : localStorageKey.startsWith(`${apiKeyLocalStorageName}/`)) {
                removeKeys.add(localStorageKey);
            }
        }
        for (const key of removeKeys) {
            localStorage.removeItem(key);
        }
    };
    const getApiKey = (0, react_1.useCallback)((keyObject) => __awaiter(void 0, void 0, void 0, function* () {
        const localStorageKey = `${apiKeyLocalStorageName}/${keyObject.path}/${keyObject.collections.sort().join('/')}`;
        const existingApiKeyString = localStorage.getItem(localStorageKey);
        if (existingApiKeyString) {
            const existingApiKey = JSON.parse(existingApiKeyString);
            const apiKeyExpiresAt = new Date(existingApiKey.expires_at * 1000);
            const now = new Date();
            now.setMinutes(now.getMinutes() + 5);
            if (apiKeyExpiresAt > now) {
                return existingApiKey;
            }
        }
        const keyPath = keyObject.path;
        const body = {
            collections: keyObject.collections.map((collection) => `${tenant}:${collection}`),
        };
        try {
            const key = yield createApiKey(keyPath, body);
            localStorage.setItem(localStorageKey, JSON.stringify(key));
            return key;
        }
        catch (e) {
            console.error('Failed to create Typesense API key', e);
            setIsError(true);
        }
    }), [apiKeyLocalStorageName, setIsError, createApiKey]);
    if (isError) {
        console.warn('Failed to create Typesense API key');
    }
    return ((0, jsx_runtime_1.jsx)(exports.TypesenseContext.Provider, { value: { getApiKey, invalidateApiKeys, typesenseURL }, children: children }));
};
exports.default = TypesenseContextProvider;
