var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import { i18nInit, initializeApi } from 'platform-webapp-frontend';
import { StrictMode, Suspense } from 'react';
import { render } from 'react-dom';
import App from './common/App';
import { applyProductionConfig, config } from './common/util';
import './scss/global.scss';
import testUser from './util/test-user';
//import reportWebVitals from './reportWebVitals';
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// keep existing placeholder content during React load
const rootElement = document.getElementById('root');
const fallback = (_jsx("div", { dangerouslySetInnerHTML: {
        __html: (_a = rootElement === null || rootElement === void 0 ? void 0 : rootElement.innerHTML) !== null && _a !== void 0 ? _a : 'Loading&hellip;',
    } }));
i18nInit((language, namespace) => {
    return import(`./locales/${language}/${namespace}.json`);
}, {
    ns: ['common'],
    defaultNS: 'common',
});
initializeApi(config.backendURL);
window.setTestUser = config.testingMode
    ? (user) => {
        console.warn('Setting test user', user);
        testUser.set(user);
    }
    : undefined;
function doRender() {
    render(_jsx(Suspense, Object.assign({ fallback: fallback }, { children: _jsx(StrictMode, { children: _jsx(App, {}) }) })), rootElement);
}
applyProductionConfig().then(doRender);
