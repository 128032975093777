var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { NavigationBar, PlatformWebapp } from 'platform-webapp-frontend';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'platform-webapp-frontend';
import FallbackComponent from './FallbackComponent';
import { config } from './util';
import testUser from '../util/test-user';
import classes from './App.module.scss';
import Apps from '../landing/Apps';
// Only used for testing mode
const httpOptions = {
    interceptors: {
        request: ({ options }) => __awaiter(void 0, void 0, void 0, function* () {
            return Object.assign(Object.assign({}, options), { headers: Object.assign(Object.assign({}, options.headers), { 'Test-User': testUser.get() }) });
        }),
    },
};
/**
 * Root component for the entire application.
 * Rendered after the user's SSO session has been established.
 */
export default function App() {
    const { t, i18n } = useTranslation('common');
    // update document level localizable resources when the language changes
    useEffect(() => {
        document.documentElement.setAttribute('lang', i18n.language);
        let link = document.head.querySelector('link[rel="manifest"]');
        if (!link) {
            link = document.createElement('link');
            link.setAttribute('rel', 'manifest');
            document.head.append(link);
        }
        link.setAttribute('href', `/manifest.${i18n.language}.json`);
        document.title = t('title');
    }, [i18n.language, t]);
    return (_jsx("div", Object.assign({ className: "mb-5" }, { children: _jsx(PlatformWebapp, Object.assign({ header: _jsx(NavigationBar, { title: t('title') }), httpOptions: config.testingMode ? httpOptions : undefined, openid: config.openid }, { children: _jsx("div", Object.assign({ className: classes['wrapper'] }, { children: _jsx("div", Object.assign({ className: classes['wrapper-box'] }, { children: _jsx(ErrorBoundary, Object.assign({ FallbackComponent: FallbackComponent }, { children: _jsx("div", Object.assign({ className: classes['content'] }, { children: _jsx(Apps, {}) })) })) })) })) })) })));
}
