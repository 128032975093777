"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function (o, m, k, k2) {
    if (k2 === undefined)
        k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
        desc = { enumerable: true, get: function () { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function (o, m, k, k2) {
    if (k2 === undefined)
        k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function (o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function (o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule)
        return mod;
    var result = {};
    if (mod != null)
        for (var k in mod)
            if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k))
                __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useApiDownload = exports.useApi = exports.getApiUrl = exports.isApiUrl = exports.initializeApi = exports.config = void 0;
const use_http_1 = __importStar(require("use-http"));
const react_1 = require("react");
const authorization_1 = require("./authorization");
exports.config = {
    backendURL: undefined,
};
function initializeApi(url) {
    exports.config.backendURL = url;
}
exports.initializeApi = initializeApi;
function isApiUrl(url) {
    try {
        if (!exports.config.backendURL) {
            throw new Error('initializeApi(url: string) must be called before platform-webapp API functionality can be used!');
        }
        const requestUrl = new URL(url, document.location.href);
        const backendUrl = new URL(exports.config.backendURL, document.location.href);
        return requestUrl.href.startsWith(backendUrl.href);
    }
    catch (err) {
        console.error('isApiUrl', err);
        return false;
    }
}
exports.isApiUrl = isApiUrl;
function getApiUrl(pathComponents = [], queryParameters = {}, apiURL) {
    if (!exports.config.backendURL) {
        throw new Error('initializeApi(url: string) must be called before platform-webapp API functionality can be used!');
    }
    const url = new URL(pathComponents.map((component) => encodeURIComponent(component)).join('/'), apiURL ? apiURL : exports.config.backendURL);
    for (const key of Object.keys(queryParameters)) {
        if (queryParameters[key]) {
            url.searchParams.set(key, queryParameters[key]);
        }
    }
    return url;
}
exports.getApiUrl = getApiUrl;
/**
 * Calls an API endpoint
 * @param pathComponents Request path, as an array of the path's components (automatically escaped)
 */
function useApi(pathComponents, queryParameters, options, dependencies) {
    var _a;
    const authorization = (0, authorization_1.useAuthorization)();
    // Remove trailing slash from the URL
    const url = getApiUrl(pathComponents, queryParameters, options.apiURL).toString().replace(/\/$/, '');
    const includeToken = (_a = options.includeToken) !== null && _a !== void 0 ? _a : !options.apiURL;
    if (dependencies !== undefined) {
        dependencies = [...dependencies, url];
    }
    return (0, use_http_1.default)(url, Object.assign(Object.assign({ cachePolicy: use_http_1.CachePolicies.NO_CACHE }, options), { headers: Object.assign({ Accept: 'application/json', 'Content-Type': 'application/json' }, options.headers), interceptors: {
            request: (request) => __awaiter(this, void 0, void 0, function* () {
                var _b, _c, _d;
                if ((_b = options.interceptors) === null || _b === void 0 ? void 0 : _b.request) {
                    request.options = yield options.interceptors.request(request);
                }
                return Object.assign(Object.assign({}, request.options), { headers: Object.assign(Object.assign({}, request.options.headers), (includeToken ? { Authorization: `Bearer ${(_d = (_c = authorization.ref) === null || _c === void 0 ? void 0 : _c.current) === null || _d === void 0 ? void 0 : _d.accessToken}` } : {})) });
            }),
        } }), dependencies);
}
exports.useApi = useApi;
/**
 * Returns a function that calls an API endpoint, then downloads the response as a file.
 * @param pathComponents Request path, as an array of the path's components (automatically escaped)
 */
function useApiDownload(pathComponents, queryParameters, options, dependencies) {
    const { get } = useApi(pathComponents, queryParameters, Object.assign(Object.assign({}, options), { responseType: 'blob', headers: Object.assign(Object.assign({}, options.headers), { Accept: '*' }) }), dependencies);
    const download = (0, react_1.useCallback)((filename, extraUrlSuffix) => __awaiter(this, void 0, void 0, function* () {
        const response = yield get(extraUrlSuffix);
        const url = URL.createObjectURL(response);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        // The timeout doesn't seem to be strictly necessary,
        // but just in case a browser extension messes with the click event or something...
        setTimeout(() => {
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        }, 1000);
    }), [get]);
    return download;
}
exports.useApiDownload = useApiDownload;
