"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_bootstrap_1 = require("react-bootstrap");
const react_i18next_1 = require("react-i18next");
const authorization_1 = require("../authorization");
const LoginMessage = () => {
    const { t } = (0, react_i18next_1.useTranslation)('webapp-defaults');
    const authorization = (0, authorization_1.useAuthorization)();
    return ((0, jsx_runtime_1.jsx)(react_bootstrap_1.Container, { children: (0, jsx_runtime_1.jsxs)("div", { className: "mt-5 d-flex flex-column align-items-center gap-3", children: [(0, jsx_runtime_1.jsx)("span", { children: t('misc.need-to-login') }), (0, jsx_runtime_1.jsx)(react_bootstrap_1.Button, { onClick: () => authorization.triggerLogin(), children: t('nav.login') })] }) }));
};
exports.default = LoginMessage;
