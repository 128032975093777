export function resolveLocalizedString(s, languages) {
    if (s === undefined) {
        return;
    }
    if (typeof s === 'string') {
        return s;
    }
    let allLanguages = [];
    if (typeof languages === 'string') {
        allLanguages = [languages];
    }
    else if (!Array.isArray(languages)) {
        allLanguages = languages.slice();
    }
    allLanguages.push('en');
    for (const lang of allLanguages) {
        if (s[lang]) {
            return s[lang];
        }
    }
}
export function resolvePlaceholders(s, user) {
    var _a;
    if (s === undefined) {
        return;
    }
    if (user === undefined) {
        return s;
    }
    s = s.replaceAll('$TENANT$', user.tenant);
    s = s.replaceAll('$LOCALE$', (_a = user.locale) !== null && _a !== void 0 ? _a : '');
    return s;
}
