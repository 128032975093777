var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b;
import { merge } from 'lodash';
import { filterLeaves } from 'platform-webapp-util';
const formatUrl = (url) => {
    if (url.charAt(url.length - 1) !== '/') {
        return url + '/';
    }
    return url;
};
/** Deployment-specific configuration */
export const config = {
    backendURL: formatUrl(process.env.REACT_APP_BACKEND_URL || `${document.location.origin}/api/`),
    testingMode: process.env.REACT_APP_MIPRO_TESTING_MODE,
    openid: {
        issuer: (_a = process.env.REACT_APP_KEYCLOAK_ISSUER) !== null && _a !== void 0 ? _a : '',
        clientId: (_b = process.env.REACT_APP_KEYCLOAK_CLIENT_ID) !== null && _b !== void 0 ? _b : '',
    },
};
/**
 * Extend `config` with values from the JSON.
 * Must be called before rendering the application.
 */
export function applyProductionConfig() {
    return __awaiter(this, void 0, void 0, function* () {
        if ('production' !== process.env.NODE_ENV && process.env.CI !== 'true') {
            return;
        }
        const response = yield fetch('/env/config.json');
        if (!response.ok) {
            console.warn(`Unable to fetch config.json (${response.status} ${response.statusText})`, yield response.text());
            return;
        }
        merge(config, 
        // ignore empty strings to simplify docker-entrypoint.sh
        filterLeaves(yield response.json(), (value) => value !== ''));
    });
}
