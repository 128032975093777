"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_bootstrap_1 = require("react-bootstrap");
const react_i18next_1 = require("react-i18next");
const ErrorAlert = (props) => {
    const { t } = (0, react_i18next_1.useTranslation)('webapp-defaults');
    return ((0, jsx_runtime_1.jsx)(react_bootstrap_1.Alert, { variant: "danger", children: (0, jsx_runtime_1.jsxs)(react_bootstrap_1.Container, { className: "m-2", children: [(0, jsx_runtime_1.jsx)(react_bootstrap_1.Row, { className: "justify-content-center mb-3", children: props.message }), (0, jsx_runtime_1.jsx)(react_bootstrap_1.Row, { className: "justify-content-center", children: (0, jsx_runtime_1.jsx)(react_bootstrap_1.NavLink, { href: props.redirectPath, style: { width: 'fit-content' }, children: (0, jsx_runtime_1.jsx)(react_bootstrap_1.Button, { children: t('misc.return') }) }) })] }) }));
};
exports.default = ErrorAlert;
