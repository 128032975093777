import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, Button, Container } from 'react-bootstrap';
import { useTranslation } from 'platform-webapp-frontend';
/**
 * Generic FallbackComponent for ErrorBoundary. Shows the error message and a reset button.
 */
export default function FallbackComponent({ error, resetErrorBoundary }) {
    const { t } = useTranslation('common');
    console.error(error);
    return (_jsx(_Fragment, { children: _jsx(Container, Object.assign({ "data-testid": "main-container", className: "pt-2" }, { children: _jsxs(Alert, Object.assign({ variant: "danger" }, { children: [_jsx("p", { children: t('fallback.title') }), _jsx("pre", { children: error.message }), _jsx(Button, Object.assign({ variant: "primary", onClick: () => {
                            window.location.reload();
                        } }, { children: t('fallback.reset') }))] })) })) }));
}
