"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_bootstrap_1 = require("react-bootstrap");
const react_instantsearch_1 = require("react-instantsearch");
const i18n_1 = require("../../i18n");
const DatetimeRangePicker = ({ attribute }) => {
    const { t } = (0, i18n_1.useTranslation)('webapp-defaults');
    const { canRefine, refine, range } = (0, react_instantsearch_1.useRange)({ attribute });
    const [startDate, setStartDate] = (0, react_1.useState)(undefined);
    const [startTime, setStartTime] = (0, react_1.useState)('00:00:00');
    const [endDate, setEndDate] = (0, react_1.useState)(undefined);
    const [endTime, setEndTime] = (0, react_1.useState)('23:59:59');
    const dates = [
        { date: startDate, setDate: setStartDate, time: startTime, setTime: setStartTime, label: t('filters.start-date') },
        { date: endDate, setDate: setEndDate, time: endTime, setTime: setEndTime, label: t('filters.end-date') },
    ];
    (0, react_1.useEffect)(() => {
        var _a, _b;
        const constructDate = (d, t) => new Date(`${d.toISOString().split('T')[0]}T${t}`).getTime();
        const startDateTime = Math.max(startDate ? constructDate(startDate, startTime) : -Infinity, (_a = range.min) !== null && _a !== void 0 ? _a : -Infinity);
        const endDateTime = Math.min(endDate ? constructDate(endDate, endTime) : Infinity, (_b = range.max) !== null && _b !== void 0 ? _b : Infinity);
        if (canRefine) {
            refine([startDateTime, endDateTime]);
        }
    }, [refine, startDate, endDate, canRefine, startTime, endTime, range.min, range.max]);
    const validateDate = (date) => {
        if (!date) {
            return undefined;
        }
        const time = date.getTime();
        if (isNaN(time)) {
            return undefined;
        }
        return date;
    };
    return ((0, jsx_runtime_1.jsx)("div", { className: "d-flex gap-2", children: dates.map(({ date, setDate, time, setTime, label }, index) => {
            return ((0, jsx_runtime_1.jsxs)("div", { className: "d-flex flex-column gap-2 flex-grow-1", children: [(0, jsx_runtime_1.jsx)(react_bootstrap_1.Form.Label, { children: label }), (0, jsx_runtime_1.jsx)(react_bootstrap_1.Form.Control, { type: "date", disabled: !canRefine, value: date === null || date === void 0 ? void 0 : date.toISOString().split('T')[0], onChange: (e) => setDate(validateDate(new Date(e.target.value))) }), (0, jsx_runtime_1.jsx)(react_bootstrap_1.Form.Control, { type: "time", disabled: !canRefine, value: time, onChange: (e) => setTime(e.target.value) })] }, index));
        }) }));
};
exports.default = DatetimeRangePicker;
